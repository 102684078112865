import Alert from '@/common/components/Alert';

const TrialEndedAlert = () => {
  return (
    <Alert.WithButton
      link="/checkout"
      buttonText="Upgrade Now"
      containerProps={{
        ['data-testid']: 'trial-ended-banner',
      }}
    >
      Your free trial period has ended, subscribe to post more jobs & get top
      applicants for your hiring needs.
    </Alert.WithButton>
  );
};

export default TrialEndedAlert;
