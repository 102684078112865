import clsx from 'clsx';
import Link from 'next/link';

const Alert = ({ children, type = 'primary', className, ...props }) => {
  return (
    <div
      className={clsx(
        'text-center text-sm font-semibold leading-[21px]',
        className,
        {
          '!w-full rounded-lg bg-[linear-gradient(90deg,#6913D8_0%,#8D26F4_100%)] py-[18px]  !text-white':
            type === 'warning',
        }
      )}
      {...props}
    >
      {children}
    </div>
  );
};

Alert.WithButton = ({
  children,
  link = '/checkout',
  buttonText,
  containerProps = {},
  buttonProps = {},
  textContainerProps = {},
}) => {
  const { className: containerClassName, ...restContainerProps } =
    containerProps;
  const { className: textContainerClassName, ...restTextContainerProps } =
    textContainerProps;
  const { className: buttonClassName, ...restButtonProps } = buttonProps;

  return (
    <div
      className={clsx(
        'mb-6 flex flex-col items-center justify-between rounded-lg bg-[linear-gradient(90deg,#6913D8_0%,#8D26F4_100%)] py-[11px] px-6 md:flex-row',
        containerClassName
      )}
      {...restContainerProps}
    >
      <span
        className={clsx(
          'inline-block text-center text-xs font-medium leading-[150%] text-white md:text-left',
          textContainerClassName
        )}
        {...restTextContainerProps}
      >
        {children}
      </span>

      <Link
        href={link}
        className={clsx(
          'ml-0 mt-4 shrink-0 rounded-[71px] bg-white py-[10px] px-4 text-xs font-bold leading-[100%] text-violet-500 hover:!text-violet-500 md:ml-4 md:mt-0',
          buttonClassName
        )}
        {...restButtonProps}>

        {buttonText}

      </Link>
    </div>
  );
};

export default Alert;
