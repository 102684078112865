import FlexibleNoQuotaAlert from '@/modules/subscription/components/Alert/FlexibleNoQuota';
import SubscriptionEndedAlert from '@/modules/subscription/components/Alert/SubscriptionEnded';
import TrialEndedAlert from '@/modules/subscription/components/Alert/TrialEnded';
import TrialModeAlert from '@/modules/subscription/components/Alert/TrialMode';
import useCompanySubscriptionQuery from '@/modules/subscription/hooks/queries/useCompanySubscriptionQuery';
import { transformSubscriptionInfo } from '@/modules/subscription/utils';

const HomePageAlert = () => {
  const { data: subscriptionData } = useCompanySubscriptionQuery();

  const subscription = subscriptionData?.data?.result;

  const {
    subscriptionStatusIsFlexible,
    subscriptionStatusIsSubscribe,
    subscriptionStatusIsTrial,
    expired,
    usedJobCredit,
    availableJobCredit,
  } = transformSubscriptionInfo({
    subscription,
  });

  const subscriptionUpgraded = subscription?.upgraded;

  const trialExpired = expired && subscriptionStatusIsTrial;
  const subscriptionExpired = expired && subscriptionStatusIsSubscribe;

  const purchasedCreditAndNoUsableCredit =
    usedJobCredit > 0 && availableJobCredit <= 0;

  if (subscriptionExpired) return <SubscriptionEndedAlert />;

  if (trialExpired) return <TrialEndedAlert />;

  if (subscriptionStatusIsTrial && !subscriptionUpgraded)
    return <TrialModeAlert />;

  if (subscriptionStatusIsFlexible && purchasedCreditAndNoUsableCredit)
    return <FlexibleNoQuotaAlert />;

  return null;
};

export default HomePageAlert;
