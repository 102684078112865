import Alert from '@/common/components/Alert';

const FlexibleNoQuotaAlert = () => {
  return (
    <Alert.WithButton
      link="/checkout"
      buttonText="Upgrade Now"
      containerProps={{
        ['data-testid']: 'flexible-no-quota-banner',
      }}
    >
      You have no credit left, upgrade to post more jobs & get top applicants
      for your hiring needs
    </Alert.WithButton>
  );
};

export default FlexibleNoQuotaAlert;
