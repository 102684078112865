import { message, Modal } from 'antd';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import Button from '@/common/components/Button';
import { useStoreApi } from '@/common/contexts/useApi';
import { parseErrorMessage } from '@/common/utils';
import UndrawAccessDenied from '@/modules/permissions/icons/UndrawAccessDenied';
import { deleteRequestAccessPermission } from '@/modules/permissions/services/PermissionService';
import { Resource } from '@/modules/permissions/types';
import { GatePermission } from '@/modules/permissions/utils/generator/permissions';

import * as styles from './index.module.scss';

const DeniedAccessModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const router = useRouter();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { axios } = useStoreApi();

  const { userEmail, resource, openDeniedModal, companyChangeRequestId } =
    router.query;

  const pageName = useMemo(() => {
    const currentResource = resource as Resource;
    if (GatePermission.isResource({ resource: currentResource })) {
      return GatePermission.getPageName({
        resource: currentResource,
      });
    }

    return resource;
  }, [resource]);

  const handleClickDone = () => {
    setIsVisible(false);
    delete router.query.openDeniedModal;
    router.replace(router, undefined, {
      shallow: true,
    });
  };

  const denyAccessPermissionMember = async () => {
    setIsSubmitting(true);
    try {
      await deleteRequestAccessPermission(axios, {
        companyChangeRequestId,
      });
    } catch (error) {
      const errorMessage = parseErrorMessage(error);
      message.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (openDeniedModal) {
      setIsVisible(true);
    }
  }, [openDeniedModal]);

  useEffect(() => {
    if (isVisible) {
      denyAccessPermissionMember();
    }
  }, [isVisible]);

  return (
    <Modal
      className={clsx(styles['denied_access_modal'])}
      open={isVisible}
      maskClosable={false}
      width={361}
      footer={null}
      onCancel={() => setIsVisible(false)}
      centered
      closable={!isSubmitting}
    >
      <div className="p-4">
        <div className="mt-[60px] flex">
          <UndrawAccessDenied className="mx-auto" />
        </div>
        <div className="mt-[24px] text-center">
          <span className="block text-base font-bold leading-[130%] text-neutral-1000">
            Access Denied
          </span>
          <p className="mt-1 text-xs font-medium leading-[150%] text-neutral-600">
            {pageName ?? '{{pageName}}'} access request denied. An email has
            been sent to {userEmail ?? '{{userEmail}}'} to notify.
          </p>
          <div className="mt-[24px] flex items-center justify-center space-x-[32px]">
            <ManageAccessLink />
            <Button
              onClick={handleClickDone}
              loading={isSubmitting}
              htmlType="submit"
              className="!h-[36px] !w-[108px] !rounded-[71px] !text-xs hover:!bg-violet-500 hover:!text-white"
            >
              Done
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const ManageAccessLink = () => {
  return (
    <Link
      href="/manage-team"
      target="_blank"
      className="text-xs font-bold text-violet-500 hover:!text-violet-500"
    >
      Manage Access
    </Link>
  );
};

export default DeniedAccessModal;
