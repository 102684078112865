import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { BackendRes } from '@/common/types';
import useMyUserCompanyQuery from '@/modules/company/hooks/queries/useMyUserCompanyQuery';

import SubscriptionService from '../../services/SubscriptionService';
import { Subscription } from '../../types';
import { CompanySubscriptionKeys } from '../../utils/generators/keys';

type UseCompanySubscriptionQueryProps = {
  queryParams?: Partial<{
    companyId: string;
  }>;
  options?: UseQueryOptions<
    BackendRes<{
      result: Subscription;
    }>
  >;
};

/**
 * useGetSubscriptionDetail
 * @see {transformSubscriptionInfo} Most likely you will use this function to transform the subscription data to use it in your component
 */

const useCompanySubscriptionQuery = ({
  queryParams,
  options,
}: UseCompanySubscriptionQueryProps = {}) => {
  const { data: companyData } = useMyUserCompanyQuery();
  const companyId = companyData?.data.result.companyId;

  return useQuery({
    queryKey: [...CompanySubscriptionKeys.all, queryParams],
    queryFn: async () => {
      const res = await SubscriptionService.getCompanySubscription({
        axiosConfig: {
          params: {
            companyId: companyId,
            ...queryParams,
          },
        },
      });

      return res.data;
    },
    enabled: !!companyId,
    ...options,
  });
};

export default useCompanySubscriptionQuery;
