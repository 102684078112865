import clsx from 'clsx';

const HomepageContentCard = ({
  children = null,
  title = null,
  titleAction = null,
  ...props
}) => {
  return (
    <section
      className={clsx('bg-white rounded-[6px] p-[20px]', props.cardClassName)}
      style={{
        boxShadow:
          '0px 2px 12px rgba(0,0,0,0.08), 0px 1px 4px rgba(0,0,0,0.08)',
      }}
    >
      <div className="flex items-center justify-between pb-[16px] border-b border-neutral-200">
        <div className="text-[15px] font-bold text-neutral-1000">{title}</div>
        {titleAction && <div>{titleAction}</div>}
      </div>
      <div className="mt-[16px]">{children}</div>
    </section>
  );
};

export default HomepageContentCard;
