import Alert from '@/common/components/Alert';

const TrialModeAlert = () => {
  return (
    <Alert.WithButton
      link="/checkout"
      buttonText="Subscribe Now"
      containerProps={{
        ['data-testid']: 'trial-mode-banner',
      }}
    >
      You’re currently in Trial Mode. Convinced enough?
    </Alert.WithButton>
  );
};

export default TrialModeAlert;
