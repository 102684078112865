const UndrawAccessDenied = (props) => {
  return (
    <svg
      width="121"
      height="115"
      viewBox="0 0 121 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1094_9162)">
        <path
          d="M101.492 27.257H100.861V9.99227C100.861 7.34216 99.8068 4.80058 97.93 2.92667C96.0532 1.05275 93.5077 1.30344e-09 90.8536 1.30344e-09H54.2192C52.905 -2.11925e-05 51.6036 0.258416 50.3894 0.760555C49.1752 1.26269 48.072 1.9987 47.1427 2.92655C46.2134 3.85441 45.4762 4.95594 44.9733 6.16825C44.4703 7.38056 44.2114 8.67991 44.2114 9.99211V104.708C44.2114 107.358 45.2658 109.899 47.1426 111.773C49.0194 113.647 51.5648 114.7 54.219 114.7H90.8531C93.5073 114.7 96.0527 113.647 97.9295 111.773C99.8063 109.899 100.861 107.358 100.861 104.708V39.5463H101.491L101.492 27.257Z"
          fill="#3F3D56"
        />
        <path
          d="M98.8085 9.32301V105.377C98.8083 107.386 98.0088 109.313 96.586 110.734C95.1632 112.155 93.2335 112.953 91.2211 112.954H53.8351C51.822 112.954 49.8911 112.156 48.4673 110.735C47.0435 109.314 46.2433 107.387 46.2427 105.377V9.32301C46.2433 7.31301 47.0435 5.38556 48.4672 3.96458C49.891 2.5436 51.8217 1.74547 53.8348 1.74573H58.3699C58.1473 2.2925 58.0625 2.88553 58.1231 3.47267C58.1837 4.05981 58.3879 4.6231 58.7176 5.11302C59.0473 5.60295 59.4925 6.0045 60.014 6.2824C60.5356 6.56029 61.1175 6.70602 61.7086 6.70676H83.023C83.6142 6.70602 84.1961 6.56029 84.7177 6.2824C85.2392 6.0045 85.6844 5.60295 86.0141 5.11302C86.3438 4.6231 86.548 4.05981 86.6086 3.47267C86.6692 2.88553 86.5844 2.2925 86.3617 1.74573H91.2197C93.232 1.74607 95.1618 2.5443 96.5849 3.96493C98.008 5.38555 98.8078 7.31229 98.8085 9.32155V9.32301Z"
          fill="white"
        />
        <path
          d="M72.5184 47.8825C81.1385 47.8825 88.1266 40.9052 88.1266 32.2982C88.1266 23.6912 81.1385 16.7139 72.5184 16.7139C63.8982 16.7139 56.9102 23.6912 56.9102 32.2982C56.9102 40.9052 63.8982 47.8825 72.5184 47.8825Z"
          fill="#6913D8"
        />
        <path
          d="M89.5411 64.651H56.3193C56.1562 64.6508 55.9998 64.586 55.8845 64.4709C55.7691 64.3557 55.7043 64.1996 55.7041 64.0367V55.8086C55.7043 55.6457 55.7692 55.4896 55.8845 55.3745C55.9998 55.2593 56.1562 55.1945 56.3193 55.1943H89.5411C89.7042 55.1945 89.8605 55.2593 89.9759 55.3745C90.0912 55.4896 90.1561 55.6457 90.1563 55.8086V64.0369C90.1561 64.1997 90.0912 64.3558 89.9759 64.471C89.8605 64.5861 89.7042 64.6509 89.5411 64.6511V64.651ZM56.3193 55.44C56.2214 55.44 56.1275 55.4789 56.0583 55.548C55.9891 55.6171 55.9502 55.7108 55.9501 55.8086V64.0369C55.9502 64.1346 55.9891 64.2283 56.0583 64.2974C56.1275 64.3665 56.2214 64.4054 56.3193 64.4055H89.5411C89.639 64.4054 89.7328 64.3665 89.802 64.2974C89.8713 64.2283 89.9102 64.1346 89.9103 64.0369V55.8086C89.9102 55.7108 89.8713 55.6171 89.802 55.548C89.7328 55.4789 89.639 55.44 89.5411 55.44H56.3193Z"
          fill="#E6E6E6"
        />
        <path
          d="M66.5938 58.1576C66.4795 58.1576 66.37 58.2029 66.2892 58.2835C66.2085 58.3642 66.1631 58.4736 66.1631 58.5876C66.1631 58.7017 66.2085 58.811 66.2892 58.8917C66.37 58.9723 66.4795 59.0176 66.5938 59.0176H86.8958C87.01 59.022 87.1212 58.9809 87.205 58.9034C87.2888 58.8259 87.3384 58.7183 87.3428 58.6043C87.3471 58.4903 87.306 58.3793 87.2283 58.2956C87.1507 58.2119 87.043 58.1624 86.9288 58.1581C86.9225 58.1578 86.9162 58.1577 86.9099 58.1578L66.5938 58.1576Z"
          fill="#E6E6E6"
        />
        <path
          d="M66.5938 60.7372C66.4795 60.7372 66.37 60.7825 66.2892 60.8631C66.2085 60.9438 66.1631 61.0531 66.1631 61.1672C66.1631 61.2812 66.2085 61.3906 66.2892 61.4713C66.37 61.5519 66.4795 61.5972 66.5938 61.5972H86.8958C87.01 61.6016 87.1212 61.5605 87.205 61.483C87.2888 61.4055 87.3384 61.2979 87.3428 61.1839C87.3471 61.0699 87.306 60.9589 87.2283 60.8752C87.1507 60.7915 87.043 60.742 86.9288 60.7376C86.9225 60.7374 86.9162 60.7373 86.9099 60.7373H66.5937L66.5938 60.7372Z"
          fill="#E6E6E6"
        />
        <path
          d="M89.5411 78.3657H56.3193C56.1562 78.3655 55.9998 78.3007 55.8845 78.1856C55.7691 78.0704 55.7043 77.9143 55.7041 77.7514V69.5233C55.7043 69.3605 55.7692 69.2043 55.8845 69.0892C55.9998 68.9741 56.1562 68.9093 56.3193 68.9091H89.5411C89.7042 68.9093 89.8605 68.9741 89.9759 69.0892C90.0912 69.2043 90.1561 69.3605 90.1563 69.5233V77.7516C90.156 77.9144 90.0911 78.0705 89.9758 78.1856C89.8605 78.3007 89.7041 78.3655 89.5411 78.3657ZM56.3193 69.1547C56.2214 69.1548 56.1275 69.1936 56.0583 69.2627C55.9891 69.3319 55.9502 69.4256 55.9501 69.5233V77.7516C55.9502 77.8493 55.9891 77.9431 56.0583 78.0122C56.1275 78.0813 56.2214 78.1201 56.3193 78.1202H89.5411C89.639 78.1201 89.7328 78.0813 89.802 78.0122C89.8713 77.9431 89.9102 77.8493 89.9103 77.7516V69.5233C89.9102 69.4256 89.8713 69.3319 89.802 69.2627C89.7328 69.1936 89.639 69.1548 89.5411 69.1547H56.3193Z"
          fill="#E6E6E6"
        />
        <path
          d="M66.5938 71.9167C66.4795 71.9167 66.37 71.9621 66.2892 72.0427C66.2085 72.1233 66.1631 72.2327 66.1631 72.3468C66.1631 72.4608 66.2085 72.5702 66.2892 72.6508C66.37 72.7315 66.4795 72.7768 66.5938 72.7768H86.8958C87.0084 72.7761 87.1163 72.7315 87.1963 72.6524C87.2764 72.5734 87.3222 72.4661 87.3241 72.3537C87.3259 72.2413 87.2836 72.1326 87.2062 72.051C87.1288 71.9694 87.0224 71.9212 86.9099 71.9169H66.5938V71.9167Z"
          fill="#E6E6E6"
        />
        <path
          d="M66.5938 74.4971C66.4795 74.4971 66.37 74.5424 66.2892 74.623C66.2085 74.7037 66.1631 74.813 66.1631 74.9271C66.1631 75.0411 66.2085 75.1505 66.2892 75.2312C66.37 75.3118 66.4795 75.3571 66.5938 75.3571H86.8958C87.0084 75.3565 87.1163 75.3118 87.1963 75.2328C87.2764 75.1537 87.3222 75.0465 87.3241 74.934C87.3259 74.8216 87.2836 74.713 87.2062 74.6313C87.1288 74.5497 87.0224 74.5015 86.9099 74.4972H66.5938V74.4971Z"
          fill="#E6E6E6"
        />
        <path
          d="M89.5411 92.1252H56.3193C56.1562 92.125 55.9998 92.0603 55.8845 91.9451C55.7691 91.83 55.7043 91.6738 55.7041 91.511V83.2828C55.7043 83.12 55.7692 82.9639 55.8845 82.8487C55.9998 82.7336 56.1562 82.6688 56.3193 82.6686H89.5411C89.7042 82.6688 89.8605 82.7336 89.9759 82.8487C90.0912 82.9639 90.1561 83.12 90.1563 83.2828V91.5111C90.1561 91.674 90.0912 91.8301 89.9759 91.9452C89.8605 92.0604 89.7042 92.1252 89.5411 92.1254V92.1252ZM56.3193 82.9142C56.2214 82.9143 56.1275 82.9532 56.0583 83.0223C55.9891 83.0914 55.9502 83.1851 55.9501 83.2828V91.5111C55.9502 91.6089 55.9891 91.7026 56.0583 91.7717C56.1275 91.8408 56.2214 91.8797 56.3193 91.8797H89.5411C89.639 91.8797 89.7328 91.8408 89.802 91.7717C89.8713 91.7026 89.9102 91.6089 89.9103 91.5111V83.2828C89.9102 83.1851 89.8713 83.0914 89.802 83.0223C89.7328 82.9532 89.639 82.9143 89.5411 82.9142H56.3193Z"
          fill="#E6E6E6"
        />
        <path
          d="M66.5938 85.6768C66.4795 85.6768 66.37 85.7221 66.2892 85.8027C66.2085 85.8833 66.1631 85.9927 66.1631 86.1068C66.1631 86.2208 66.2085 86.3302 66.2892 86.4108C66.37 86.4915 66.4795 86.5368 66.5938 86.5368H86.8958C86.9523 86.5389 87.0087 86.53 87.0618 86.5104C87.1148 86.4908 87.1635 86.4609 87.205 86.4226C87.2465 86.3842 87.28 86.338 87.3037 86.2867C87.3273 86.2354 87.3406 86.1799 87.3428 86.1235C87.3449 86.067 87.3359 86.0107 87.3163 85.9577C87.2967 85.9048 87.2668 85.8562 87.2283 85.8148C87.1899 85.7733 87.1437 85.7398 87.0923 85.7162C87.0409 85.6926 86.9853 85.6794 86.9288 85.6772C86.9225 85.677 86.9162 85.6769 86.9099 85.6769H66.5937L66.5938 85.6768Z"
          fill="#E6E6E6"
        />
        <path
          d="M66.5938 88.2568C66.4795 88.2568 66.37 88.3021 66.2892 88.3828C66.2085 88.4634 66.1631 88.5728 66.1631 88.6869C66.1631 88.8009 66.2085 88.9103 66.2892 88.9909C66.37 89.0716 66.4795 89.1169 66.5938 89.1169H86.8958C86.9523 89.119 87.0087 89.1101 87.0618 89.0905C87.1148 89.0709 87.1635 89.041 87.205 89.0026C87.2465 88.9643 87.28 88.9181 87.3037 88.8668C87.3273 88.8155 87.3406 88.76 87.3428 88.7036C87.3449 88.6471 87.3359 88.5908 87.3163 88.5378C87.2967 88.4849 87.2668 88.4363 87.2283 88.3948C87.1899 88.3534 87.1437 88.3199 87.0923 88.2963C87.0409 88.2727 86.9853 88.2595 86.9288 88.2573C86.9225 88.2571 86.9162 88.257 86.9099 88.257H66.5937L66.5938 88.2568Z"
          fill="#E6E6E6"
        />
        <path
          d="M120.187 115.001H0.813245C0.640135 115.001 0.5 114.925 0.5 114.832C0.5 114.738 0.640297 114.663 0.813245 114.663H120.187C120.36 114.663 120.5 114.738 120.5 114.832C120.5 114.925 120.36 115.001 120.187 115.001Z"
          fill="#E6E6E6"
        />
        <path
          d="M115.131 108.421C114.621 108.6 114.074 108.645 113.541 108.551C113.009 108.458 112.51 108.229 112.091 107.886C111.027 106.994 110.693 105.524 110.421 104.163L109.618 100.137L111.3 101.294C112.509 102.125 113.746 102.983 114.584 104.188C115.421 105.392 115.787 107.036 115.114 108.34"
          fill="#E6E6E6"
        />
        <path
          d="M114.871 113.575C115.083 112.035 115.301 110.476 115.152 108.919C115.02 107.537 114.598 106.187 113.738 105.08C113.282 104.494 112.726 103.991 112.097 103.595C111.933 103.492 111.782 103.752 111.945 103.855C113.034 104.542 113.876 105.557 114.351 106.752C114.874 108.082 114.958 109.532 114.868 110.945C114.813 111.799 114.698 112.648 114.581 113.496C114.572 113.534 114.578 113.574 114.597 113.609C114.617 113.643 114.648 113.668 114.686 113.68C114.725 113.691 114.766 113.685 114.8 113.666C114.835 113.646 114.86 113.614 114.871 113.575H114.871Z"
          fill="#F2F2F2"
        />
        <path
          d="M113.348 111.104C113.129 111.436 112.828 111.706 112.475 111.889C112.121 112.071 111.726 112.159 111.329 112.145C110.307 112.096 109.454 111.384 108.687 110.708L106.419 108.708L107.92 108.636C109 108.585 110.108 108.537 111.137 108.869C112.166 109.202 113.114 110.002 113.302 111.064"
          fill="#E6E6E6"
        />
        <path
          d="M115.469 114.46C114.451 112.66 113.268 110.659 111.156 110.019C110.569 109.842 109.953 109.779 109.342 109.832C109.149 109.848 109.197 110.145 109.39 110.129C110.415 110.044 111.437 110.314 112.286 110.894C113.102 111.448 113.738 112.22 114.275 113.038C114.605 113.538 114.9 114.061 115.195 114.583C115.289 114.749 115.565 114.629 115.469 114.46Z"
          fill="#F2F2F2"
        />
        <path
          d="M60.4643 62.4521C61.8913 62.4521 63.0481 61.297 63.0481 59.8722C63.0481 58.4473 61.8913 57.2922 60.4643 57.2922C59.0372 57.2922 57.8804 58.4473 57.8804 59.8722C57.8804 61.297 59.0372 62.4521 60.4643 62.4521Z"
          fill="#6913D8"
        />
        <path
          d="M59.2272 59.3903C59.1974 59.3903 59.1733 59.5828 59.1733 59.8202C59.1733 60.0576 59.1974 60.2503 59.2272 60.2503H61.7679C61.7976 60.2542 61.8222 60.0649 61.8227 59.8275C61.8392 59.6796 61.821 59.53 61.7697 59.3904H59.2272V59.3903Z"
          fill="#E6E6E6"
        />
        <path
          d="M60.4643 76.2172C61.8913 76.2172 63.0481 75.0622 63.0481 73.6373C63.0481 72.2125 61.8913 71.0574 60.4643 71.0574C59.0372 71.0574 57.8804 72.2125 57.8804 73.6373C57.8804 75.0622 59.0372 76.2172 60.4643 76.2172Z"
          fill="#6913D8"
        />
        <path
          d="M59.2272 73.1554C59.1974 73.1554 59.1733 73.3479 59.1733 73.5853C59.1733 73.8227 59.1974 74.0154 59.2272 74.0154H61.7679C61.7976 74.0193 61.8222 73.83 61.8227 73.5926C61.8392 73.4448 61.821 73.2952 61.7697 73.1556H59.2272V73.1554Z"
          fill="#E6E6E6"
        />
        <path
          d="M60.4643 89.9768C61.8913 89.9768 63.0481 88.8217 63.0481 87.3968C63.0481 85.972 61.8913 84.8169 60.4643 84.8169C59.0372 84.8169 57.8804 85.972 57.8804 87.3968C57.8804 88.8217 59.0372 89.9768 60.4643 89.9768Z"
          fill="#6913D8"
        />
        <path
          d="M59.2272 86.9149C59.1974 86.9149 59.1733 87.1075 59.1733 87.3449C59.1733 87.5823 59.1974 87.7749 59.2272 87.7749H61.7679C61.7976 87.7788 61.8222 87.5895 61.8227 87.3521C61.8392 87.2043 61.821 87.0547 61.7697 86.9151H59.2272V86.9149Z"
          fill="#E6E6E6"
        />
        <path
          d="M69.1468 28.0188L68.2324 28.9318L75.89 36.5777L76.8044 35.6647L69.1468 28.0188Z"
          fill="white"
        />
        <path
          d="M76.8045 28.9317L75.8901 28.0188L68.2325 35.6647L69.1469 36.5776L76.8045 28.9317Z"
          fill="white"
        />
        <path
          d="M15.0888 73.6142C15.3128 73.5351 15.5167 73.4078 15.6861 73.2415C15.8555 73.0751 15.9864 72.8737 16.0694 72.6514C16.1524 72.4291 16.1856 72.1914 16.1667 71.9549C16.1477 71.7184 16.077 71.4889 15.9596 71.2826L19.7686 66.9472L16.7952 66.5376L13.6341 70.7174C13.3001 70.9373 13.0584 71.2718 12.955 71.6577C12.8515 72.0436 12.8935 72.4539 13.0728 72.811C13.2521 73.1681 13.5564 73.4471 13.9279 73.5951C14.2995 73.7431 14.7125 73.75 15.0888 73.6142Z"
          fill="#9F616A"
        />
        <path
          d="M19.6825 112.037L17.026 112.037L15.7622 101.806L19.683 101.806L19.6825 112.037Z"
          fill="#9F616A"
        />
        <path
          d="M20.3602 114.608L11.7944 114.608V114.5C11.7945 113.617 12.1458 112.77 12.771 112.146C13.3963 111.522 14.2442 111.171 15.1285 111.171H15.1287L20.3604 111.171L20.3602 114.608Z"
          fill="#2F2E41"
        />
        <path
          d="M33.5886 109.818L31.0516 110.604L26.8062 101.207L30.5506 100.047L33.5886 109.818Z"
          fill="#9F616A"
        />
        <path
          d="M34.9993 112.073L26.8188 114.608L26.7866 114.505C26.5245 113.662 26.6085 112.749 27.0202 111.968C27.4319 111.187 28.1376 110.601 28.982 110.339L28.9822 110.339L33.9786 108.79L34.9993 112.073Z"
          fill="#2F2E41"
        />
        <path
          d="M17.6482 64.8684L13.7139 69.3674L16.794 69.6442L17.6482 64.8684Z"
          fill="#E6E6E6"
        />
        <path
          d="M16.1108 70.6675C16.1108 70.6675 14.7442 71.3497 14.5733 74.4199C14.4025 77.4901 15.0859 85.5072 15.0859 85.5072C15.0859 85.5072 14.4026 89.089 15.0859 92.3296C15.7691 95.5703 14.4026 107.339 15.2567 107.339C16.1108 107.339 20.5522 107.85 20.723 107.339C20.8939 106.827 21.0647 99.1516 21.0647 99.1516C21.0647 99.1516 22.4313 95.2286 21.0647 92.8414C21.0647 92.8414 25.8363 101.161 28.9226 107.51C29.598 108.899 34.7306 107.339 33.8765 105.804C33.0224 104.269 30.9726 97.2758 30.9726 97.2758C30.9726 97.2758 29.4351 92.1589 26.702 89.9416L28.0686 79.0255C28.0686 79.0255 30.9727 71.6913 29.2647 70.668C27.5567 69.6446 16.1108 70.6675 16.1108 70.6675Z"
          fill="#2F2E41"
        />
        <path
          d="M21.2353 42.8655C23.6882 42.8655 25.6767 40.8801 25.6767 38.4309C25.6767 35.9818 23.6882 33.9963 21.2353 33.9963C18.7824 33.9963 16.7939 35.9818 16.7939 38.4309C16.7939 40.8801 18.7824 42.8655 21.2353 42.8655Z"
          fill="#A0616A"
        />
        <path
          d="M26.1897 43.2067L20.6683 46.4473C20.6683 46.4473 18.5541 47.6361 17.7644 49.688C16.9226 51.8748 17.4119 54.9537 17.7644 55.6577C18.4476 57.0222 17.4778 60.4334 17.4778 60.4334L16.6237 68.2793C16.6237 68.2793 13.549 71.1787 15.9405 71.5199C18.332 71.8611 22.6026 71.3493 25.165 71.5199C27.7274 71.6905 30.6313 72.0317 29.7772 70.3257C28.9231 68.6196 27.8982 67.426 28.9231 64.0154C29.7251 61.3461 29.6904 51.3654 29.6374 47.1505C29.6301 46.5717 29.4746 46.0043 29.1857 45.5024C28.8968 45.0006 28.4841 44.5808 27.9869 44.2831L26.1897 43.2067Z"
          fill="#E5E5E5"
        />
        <path
          opacity="0.1"
          d="M23.7133 48.5796L24.2256 58.9839L19.2593 69.0259L18.417 68.7058L23.5418 59.3249L23.7133 48.5796Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M29.3498 61.201V60.0067L23.5425 69.5586L29.3498 61.201Z"
          fill="black"
        />
        <path
          d="M18.0832 34.3228L17.3496 34.0297C17.3496 34.0297 18.8851 32.3421 21.0208 32.4888L20.42 31.8284C20.42 31.8284 21.8884 31.2414 23.2235 32.7822C23.9253 33.5922 24.7372 34.5444 25.2439 35.6168H26.0303L25.7022 36.3384L26.8507 37.0599L25.6718 36.9308C25.7834 37.5537 25.7452 38.1942 25.5602 38.7995L25.5926 39.3698C25.5926 39.3698 24.2253 37.2574 24.2253 36.9644V37.6982C24.2253 37.6982 23.491 37.0378 23.491 36.5975L23.0905 37.1112L22.8902 36.3043L20.4204 37.1112L20.8206 36.4505L19.2851 36.6706L19.8859 35.8637C19.8859 35.8637 18.1504 36.8175 18.0837 37.6248C18.0169 38.432 17.1493 39.4591 17.1493 39.4591L16.7488 38.7253C16.7488 38.7253 16.1481 35.4234 18.0832 34.3228Z"
          fill="#2F2E41"
        />
        <path
          d="M20.9568 74.0969C21.1631 73.9793 21.3412 73.818 21.4783 73.6242C21.6155 73.4305 21.7085 73.2091 21.7506 72.9756C21.7928 72.7421 21.7832 72.5022 21.7225 72.2728C21.6618 72.0435 21.5515 71.8302 21.3993 71.648L24.377 66.7063L21.3781 66.8302L19.0102 71.5039C18.7206 71.7795 18.5423 72.1516 18.5091 72.5496C18.4759 72.9477 18.59 73.3441 18.83 73.6637C19.0699 73.9833 19.419 74.204 19.8109 74.2838C20.2029 74.3636 20.6106 74.2971 20.9568 74.0969Z"
          fill="#9F616A"
        />
        <path
          d="M27.7272 45.4243L29.0083 45.1685C29.0083 45.1685 32.8518 47.9827 31.6561 53.6113C30.4603 59.2398 25.1648 67.768 25.1648 67.768C25.1648 67.768 23.9687 69.3031 23.6274 69.6441C23.286 69.9851 22.6024 69.6441 22.9441 70.1558C23.2858 70.6676 22.4316 71.0086 22.4316 71.0086C22.4316 71.0086 18.6734 71.0086 19.0151 69.6441C19.3568 68.2796 25.1648 58.5568 25.1648 58.5568L24.3108 49.517C24.3108 49.517 23.6272 45.0831 27.7272 45.4243Z"
          fill="#E5E5E5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1094_9162">
          <rect
            width="120"
            height="115"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UndrawAccessDenied;
