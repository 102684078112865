import {
  ArrowRightOutlined,
  CheckOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  // Avatar,
  Form,
  message,
  Spin,
} from 'antd';
import clsx from 'clsx';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import Button from '@/common/components/Button';
import { DEALL_CAREER_PORTAL_URL } from '@/common/constants';
import { useStoreApi } from '@/common/contexts/useApi';
import { useStoreAuth } from '@/common/contexts/useAuth';
import { parseErrorMessage } from '@/common/utils';
import RightArrowIcon from '@/icons/RightArrowIcon';
import TalentTooltipIcon from '@/icons/TalentTooltipIcon';
import {
  getEmployerTodo,
  inviteCompanyMember,
} from '@/modules/company/services/CompanyService';
import { eventNames } from '@/modules/core/mixpanel/constants';
import useAnalytics from '@/modules/core/mixpanel/hooks/useAnalytics';
import HomePageAlert from '@/modules/home/components/Alert';
import HomepageContentCard from '@/modules/home/components/HomepageContentCard';
import InviteMemberModal from '@/modules/home/components/InviteMemberModal';
import TalentDistributionChart from '@/modules/home/components/TalentDistributionChart';
import JobService from '@/modules/jobs/services/JobService';
// import StudentService from '@/modules/jobs/services/StudentService';
import DeniedAccessModal from '@/modules/permissions/components/Departments/Modal/DeniedAccessInfo';

/**
 * POPULAR JOBS DATA
 * @type {Array}
 * Use this data to display popular jobs
 */

const EmployerTodoItem = ({ todo = {}, withDivider = true, idx = null }) => {
  const router = useRouter();
  const { analytics } = useAnalytics();

  const handleClick = useCallback(() => {
    if (todo.redirectTo) {
      analytics.track(eventNames.clickToDoApplicants);
      return router.push(todo.redirectTo);
    }
    return;
  }, [todo]);

  return (
    <>
      <div
        className={clsx(
          'flex cursor-pointer items-center justify-between py-[21px]',
          { 'pt-2': idx === 0 },
          { 'pb-2': !withDivider }
        )}
        onClick={handleClick}
      >
        <div className="flex gap-3">
          {todo.completed ? (
            <div className="flex h-[20px] max-h-[20px] min-h-[20px] w-[20px] min-w-[20px] max-w-[20px] items-center justify-center rounded-full bg-mountain-meadow-500">
              <CheckOutlined style={{ color: 'white' }} />
            </div>
          ) : (
            <div className="flex h-[20px] max-h-[20px] min-h-[20px] w-[20px] min-w-[20px] max-w-[20px] items-center justify-center rounded-full border border-neutral-300"></div>
          )}
          <div
            className={clsx('mt-[.5px] text-sm text-neutral-1000', {
              'line-through': todo.completed,
            })}
          >
            {todo.todoText}
          </div>
        </div>
        <div className="text-neutral-600">
          <RightArrowIcon />
        </div>
      </div>
      {withDivider && <hr className="text-neutral-200" />}
    </>
  );
};

const PopularJobItem = ({ job = {}, withDivider = true, idx }) => {
  const router = useRouter();
  return (
    <>
      <div
        className={clsx(
          'flex cursor-pointer items-center justify-between py-3',
          { 'pt-0': idx === 0 },
          { 'pb-0': !withDivider }
        )}
        onClick={() =>
          router.push(
            `/job-post/create?role=${encodeURIComponent(job?.jobRole?.name)}`
          )
        }
      >
        <div className="space-y-1">
          <div className="text-sm font-semibold text-neutral-1000">
            {job?.jobRole?.name}
          </div>
          <div className="flex items-center space-x-1">
            <TalentTooltipIcon className="h-[14px] w-[14px] !text-violet-500" />
            <div className="text-xs text-neutral-700">
              {`${job?.talentCount} top talents`}
            </div>
          </div>
        </div>
        <div className="p-2 text-neutral-600">
          <RightArrowIcon />
        </div>
      </div>
      {withDivider && <hr className="text-neutral-200" />}
    </>
  );
};

// const RecommendedTalentItem = ({
//   talent = {},
//   withDivider = true,
//   idx = null,
// }) => {
//   const router = useRouter();

//   return (
//     <div
//       className={clsx(
//         'flex cursor-pointer items-center justify-between rounded py-[13px]',
//         {
//           'border-b border-neutral-200': withDivider,
//         },
//         {
//           'pt-[3.5px]': idx === 0,
//         },
//         {
//           'pb-0': !withDivider,
//         }
//       )}
//       onClick={() => router.push(`/explore-talents/${talent.id}`)}
//     >
//       <div className="flex items-center gap-3">
//         <Avatar
//           src={talent.profilePictureUrl}
//           className="max-h-[36px] min-h-[36px] min-w-[36px] max-w-[36px]"
//         />
//         <div>
//           <div className="text-sm font-semibold text-neutral-800">
//             {talent.name}
//           </div>
//           <div className="text-xs text-neutral-600 line-clamp-1">
//             {talent.headline ?? '-'}
//           </div>
//         </div>
//       </div>
//       <div className="text-neutral-600">
//         <RightArrowIcon />
//       </div>
//     </div>
//   );
// };

const loaderIcon = (
  <LoadingOutlined className="text-[30px] text-violet-500" spin />
);

const Home = () => {
  const { state, dispatch } = useStoreAuth();
  const { axios } = useStoreApi();
  const [form] = Form.useForm();
  const [employerTodos, setEmployerTodos] = useState([]);
  const [jobRoleCategories, setJobRoleCategories] = useState([]);
  const [popularJobs, setPopularJobs] = useState([]);
  // const [recommendedTalents, setRecommendedTalents] = useState([]);
  const [modal, setModal] = useState(false);
  // const router = useRouter();
  const [loading, setLoading] = useState({
    employerTodos: false,
    popularJobs: false,
    recommendedTalents: false,
    inviteMember: false,
    jobRoleCategory: false,
  });
  const [employerTodoProgress, setEmployerTodoProgress] = useState(0);
  // Talent Distribution Chart && Popular Jobs
  const [activeJobCategory, setActiveJobCategory] = useState(null);
  const { analytics } = useAnalytics();

  const getEmployerTodoData = async () => {
    setLoading((prev) => ({ ...prev, employerTodos: true }));
    try {
      const { data } = await getEmployerTodo(axios, state?.companyData?.id);
      if (data) {
        const result = data?.data?.result;
        let resultLength = 0;
        if (result?.length > 0) {
          const temp = [];
          let completedCount = 0;
          result?.forEach((item) => {
            let tempTodo = { ...item };
            tempTodo.completed = item?.completedAt ? true : false;

            // Check Unprocessed Candidate
            if (item.type === 'checkUnprocessedCandidate') {
              tempTodo.todoText = [
                <strong key="highlited text">
                  {item?.targetAmount - item?.progressCount}{' '}
                  {item?.data?.jobName}
                </strong>,
                ` candidates are waiting`,
              ];
              tempTodo.redirectTo = `/job-post/${item?.data?.jobId}/candidates`;
              tempTodo.progress = `${item.progressCount}/${item.targetAmount}`;
              temp.push(tempTodo);
              resultLength += 1;
              if (item?.completedAt) completedCount += 1;
              // completedCount += item.progressCount / item.targetAmount;
            }
            // Review Potential Talent
            // else if (item.type === 'reviewPotentialTalent') {
            //   tempTodo.todoText = [
            //     <strong key="highlited text">{item?.targetAmount}</strong>,
            //     ` top talents are strong fits to join your company`,
            //   ];
            //   tempTodo.redirectTo = `/explore-talents`;
            //   tempTodo.progress = `${item.progressCount}/${item.targetAmount}`;
            //   temp.push(tempTodo);

            // }
            //   // Complete Company Profile
            else if (item.type === 'completeCompanyProfile') {
              tempTodo.todoText = [
                `Complete `,
                <strong key="highlited text">Company Profile</strong>,
                ` to attract 3x more top talents `,
              ];
              tempTodo.redirectTo = `/company/profile`;
              tempTodo.progress = `${Math.round(
                (item.progressCount / item.targetAmount) * 100
              )}%`;
              temp.push(tempTodo);
              resultLength += 1;
              if (item?.completedAt) completedCount += 1;
              // completedCount += item.progressCount / item.targetAmount;
            }
            //  Post Job
            else if (item.type === 'postJob') {
              tempTodo.todoText = (
                <strong>Start a job post & reach 20K quality talents</strong>
              );
              tempTodo.redirectTo = `/job-post/create`;
              tempTodo.progress = `${Math.round(
                (item.progressCount / item.targetAmount) * 100
              )}%`;
              temp.push(tempTodo);
              resultLength += 1;
              if (item?.completedAt) completedCount += 1;
              // completedCount += item.progressCount / item.targetAmount;
            }
            // Our Team Section
            else if (item.type === 'fillInFeaturedMembers') {
              tempTodo.todoText = [
                `Fill in `,
                <strong key="highlited text">Our Team</strong>,
                ` section on your `,
                <strong key="highlited company">Company Profile</strong>,
                ` to attract more top talents.`,
              ];
              tempTodo.redirectTo = `/company/profile/#our-team`;
              tempTodo.progress = `${Math.round(
                (item.progressCount / item.targetAmount) * 100
              )}%`;

              temp.push(tempTodo);
              resultLength += 1;
              if (item?.completedAt) completedCount += 1;
              // completedCount += item.progressCount / item.targetAmount;
            }

            // Our Team Section
            else if (item.type === 'completeFeaturedMembers') {
              tempTodo.todoText = [
                `Complete your `,
                <strong key="highlited text">Company’s Team</strong>,
                ` info (${item?.data?.blankProperties?.join(
                  ', '
                )}) now to make your
                  profile more attractive.`,
              ];
              tempTodo.redirectTo = `/company/profile/#our-team`;
              tempTodo.progress = `${Math.round(
                (item.progressCount / item.targetAmount) * 100
              )}%`;

              temp.push(tempTodo);

              resultLength += 1;
              if (item?.completedAt) completedCount += 1;
              // completedCount += item.progressCount / item.targetAmount;
            }
          });
          setEmployerTodos(temp);
          setEmployerTodoProgress(
            Math.round((completedCount / resultLength) * 100)
          );
        }
      }
    } catch (error) {
      const errorMessage = parseErrorMessage(error);
      console.log(errorMessage);
    } finally {
      setLoading((prev) => ({ ...prev, employerTodos: false }));
    }
  };

  const handleInviteUser = async (payload) => {
    setLoading((prev) => ({ ...prev, inviteMember: true }));
    try {
      const { data } = await inviteCompanyMember(axios, payload);
      if (data) {
        form.resetFields();
        setModal(false);
        message.success('Invitation Sent');
      }
    } catch (error) {
      const errorMessage = parseErrorMessage(error);
      message.error(errorMessage);
    } finally {
      setLoading((prev) => ({ ...prev, inviteMember: false }));
    }
  };

  // Implement new get popular jobs logic below
  const getPopularJobs = useCallback(async () => {
    setLoading((prev) => ({ ...prev, popularJobs: true }));
    try {
      const {
        data: { data },
      } = await JobService.getPopularJobRolesByCategoryId(
        axios,
        activeJobCategory
      );
      if (data) {
        setPopularJobs(data?.result?.jobRoleRecommendations);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, popularJobs: false }));
    }
  }, [activeJobCategory, axios]);

  // const getRecommendedTalents = async () => {
  //   setLoading((prev) => ({ ...prev, recommendedTalents: true }));
  //   try {
  //     const params = new URLSearchParams({
  //       page: 1,
  //       limit: 4,
  //     });
  //     const { data } = await StudentService.getCandidateList(axios, params);
  //     if (data) {
  //       setRecommendedTalents(data?.data?.result?.docs);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading((prev) => ({ ...prev, recommendedTalents: false }));
  //   }
  // };

  const fetchJobRoleCategories = async () => {
    setLoading((prev) => ({ ...prev, jobRoleCategory: true }));
    try {
      const { data } = await JobService.getJobRoleCategories(axios);
      if (data) {
        setJobRoleCategories(data?.data?.result?.docs);
      }
    } catch (error) {
      const errorMessage = parseErrorMessage(error);
      message.error(errorMessage);
    } finally {
      setLoading((prev) => ({ ...prev, jobRoleCategory: false }));
    }
  };

  useEffect(() => {
    if (state?.companyData?.company?.id) {
      getEmployerTodoData();
      // getRecommendedTalents();
      fetchJobRoleCategories();
    }
    // On initial login & every 3 login afterwards, set modal as unseen
    if (state?.loginCount > 1 && state?.loginCount % 3 === 0) {
      dispatch({ key: 'COMPLETE_COMPANY_PROFILE_MODAL_UNSEEN' });
    }

    analytics.track(eventNames?.viewHomePage);
  }, []);

  // Refetch popular job post on active job category change
  useEffect(() => {
    if (activeJobCategory) getPopularJobs();
  }, [activeJobCategory, getPopularJobs]);

  return (
    <main className="mb-2 p-8 md:py-0 2xl:px-[48px]">
      <Head>
        <title>Deall | Home</title>
      </Head>

      {/* Denied Access Modal */}
      <DeniedAccessModal />

      <HomePageAlert />

      <InviteMemberModal
        handleSubmit={handleInviteUser}
        form={form}
        loading={loading.inviteMember}
        visible={modal}
        setModal={setModal}
      />

      <div>
        <section className="flex flex-col justify-end gap-3 md:flex-row">
          <a
            href={DEALL_CAREER_PORTAL_URL}
            className="flex !h-[36px] items-center justify-center rounded-full bg-violet-0 px-4 text-xs font-bold text-violet-600"
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <ArrowRightOutlined className="mr-[6px] -rotate-45" />
              <span>Talent View</span>
            </div>
          </a>
          <Button
            className="!h-[36px] rounded-full !px-4 !py-3 !text-xs font-bold"
            onClick={() => window.open('https://dealls.com/referral', '_blank')}
          >
            Earn Rp1.000.000 Now
          </Button>
        </section>
        <section className="mt-6">
          <div className="flex flex-col justify-between gap-6 md:flex-row">
            <HomepageContentCard
              title="To–do List"
              cardClassName="flex-grow"
              titleAction={(() => {
                return (
                  <div className="min-w-fit text-xs font-bold text-violet-700">
                    {employerTodoProgress}% Completed
                  </div>
                );
              })()}
            >
              <Spin spinning={loading?.employerTodos} indicator={loaderIcon}>
                {employerTodos?.length > 0 ? (
                  <div className="mt-1">
                    {employerTodos?.map((todo, idx) => (
                      <EmployerTodoItem
                        key={idx}
                        todo={todo}
                        withDivider={
                          idx + 1 === employerTodos?.length ? false : true
                        }
                        idx={idx}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="flex min-h-[260px] items-center justify-center">
                    No more task for today 🎉
                  </div>
                )}
              </Spin>
            </HomepageContentCard>
            {/* <HomepageContentCard
              title="Search for Top Talents"
              cardClassName="min-w-[280px] md:max-w-[280px] desktop:min-w-[320px] desktop:max-w-[320px]"
              titleAction={(() => {
                return (
                  <div
                    className="cursor-pointer text-xs font-bold text-violet-600"
                    onClick={() => router.push('/explore-talents')}
                  >
                    See More
                  </div>
                );
              })()}
            >
              <Spin
                spinning={loading?.recommendedTalents}
                className="flex min-h-[200px] justify-center"
                indicator={loaderIcon}
              >
                {recommendedTalents?.map((talent, idx) => (
                  <RecommendedTalentItem
                    key={idx}
                    talent={talent}
                    withDivider={
                      idx + 1 === recommendedTalents?.length ? false : true
                    }
                    idx={idx}
                  />
                ))}
              </Spin>
            </HomepageContentCard> */}

            <HomepageContentCard
              title="Post Popular Jobs in 1-click"
              cardClassName="min-w-[280px] md:max-w-[280px] desktop:min-w-[320px] desktop:max-w-[320px]"
            >
              <Spin
                spinning={loading?.popularJobs}
                className="flex min-h-[200px] justify-center"
                indicator={loaderIcon}
              >
                {popularJobs?.length > 0 ? (
                  <div>
                    {popularJobs?.map((job, idx) => (
                      <PopularJobItem
                        key={idx}
                        job={job}
                        withDivider={
                          idx + 1 === popularJobs?.length ? false : true
                        }
                        idx={idx}
                      />
                    ))}
                  </div>
                ) : (
                  <div>No popular job yet.</div>
                )}
              </Spin>
            </HomepageContentCard>
          </div>

          <div className="mt-6 flex max-w-full flex-col justify-between gap-6 md:flex-row">
            <HomepageContentCard
              title="# of Available Talents"
              cardClassName="flex-1 overflow-x-auto"
            >
              <TalentDistributionChart
                activeItem={activeJobCategory}
                setActiveItem={setActiveJobCategory}
                items={jobRoleCategories}
              />
            </HomepageContentCard>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Home;
