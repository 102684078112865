import { MailOutlined } from '@ant-design/icons';
import { Form, Modal, Spin } from 'antd';

import Button from '@/common/components/Button';
import CustomInput from '@/common/components/CustomInput';
import { useStoreAuth } from '@/common/contexts/useAuth';

import * as styles from './index.module.scss';

const InviteMemberModal = ({
  visible = false,
  loading = false,
  handleSubmit,
  setModal,
  form,
}) => {
  const { state } = useStoreAuth();
  return (
    <Spin spinning={loading}>
      <Modal
        visible={visible}
        closable={loading ? false : true}
        onCancel={() => {
          if (!loading) {
            setModal(false);
          }
        }}
        footer={false}
        className={styles.invite_member_modal}
      >
        <div className="mx-auto md:text-center lg:max-w-[70%]">
          <h1 className="text-[18px] font-bold md:text-[22px]">
            Invite your team for seamless hiring collaboration
          </h1>
        </div>
        <Form form={form} onFinish={() => handleSubmit()}>
          <div>
            <CustomInput
              name="email"
              inputProps={{
                prefix: (
                  <MailOutlined
                    style={{ color: '#8D8D8D', marginRight: '4px' }}
                  />
                ),
                addonAfter: `@${state?.companyData?.company?.emailDomain}`,
                placeholder: `Username`,
                size: 'large',
              }}
              inputClass="!p-0"
              formClass="w-full min-h-fit mt-[16px] md:mt-[24px] mb-0"
              inputStyle={{
                height: 'inherit',
              }}
              rules={[
                {
                  required: true,
                  message: 'Member email username is required',
                },
                {
                  pattern: new RegExp(/^[\w-]+$/),
                  message: 'Please input valid email username',
                },
              ]}
            />
          </div>
        </Form>
        <div className="mt-[16px] flex justify-end md:mt-[24px]">
          <Button
            type="link"
            className="h-fit rounded-full text-[16px]  font-bold"
            onClick={() => setModal(false)}
          >
            <span className="block">Cancel</span>
          </Button>
          <Button
            type="primary"
            className="h-fit rounded-full py-[8px] px-[39px] text-[16px] font-bold"
            onClick={() => {
              const email =
                form.getFieldValue('email') +
                `@${state?.companyData?.company?.emailDomain}`;
              handleSubmit({ emails: [email] });
            }}
          >
            <span className="block">Submit</span>
          </Button>
        </div>
      </Modal>
    </Spin>
  );
};

export default InviteMemberModal;
