const SubscriptionKeys = {
  subscriptionPackageAll: ['subscription-package'] as const,
  subscriptionOne: ({ id = 'one' }: { id?: string }) => {
    return [...SubscriptionKeys.subscriptionPackageAll, id] as const;
  },
};

export const CompanySubscriptionKeys = {
  all: ['subscription'] as const,
};

export default SubscriptionKeys;
