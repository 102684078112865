import Alert from '@/common/components/Alert';

import useCompanySubscriptionQuery from '../../hooks/queries/useCompanySubscriptionQuery';

const SubscriptionEndedAlert = () => {
  const { data: companySubscriptionData } = useCompanySubscriptionQuery();
  const subscription = companySubscriptionData?.data?.result;

  const subscriptionPackage = subscription?.subscriptionPackage;

  const packageName = subscriptionPackage?.name || 'Custom';

  return (
    <Alert.WithButton
      link="/checkout"
      buttonText="Upgrade Now"
      containerProps={{
        ['data-testid']: 'subscription-ended-banner',
      }}
    >
      Your subscription to {packageName} has ended, re-subscribe to post more
      jobs & get top applicants for your hiring needs
    </Alert.WithButton>
  );
};

export default SubscriptionEndedAlert;
