import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { formatThousands } from '@/common/utils/formatter';
import TalentTooltipIcon from '@/icons/TalentTooltipIcon';

import styles from './index.module.scss';

/**
 * TEST DATA
 * @type {array}
 * use this data for testing purpose.
 * comment item property in TalentDistributionChart implementation to use this data.
 */
const TEST_DATA = [
  {
    id: 1,
    name: 'Data & Engineering',
    talentCount: 1000,
  },
  {
    id: 2,
    name: 'Reqruiting & People',
    talentCount: 3000,
  },
  {
    id: 3,
    name: 'Marketing & Sales',
    talentCount: 2000,
  },
  {
    id: 4,
    name: 'Finance & Accounting',
    talentCount: 5000,
  },
  {
    id: 5,
    name: 'Business Ops & Development',
    talentCount: 4000,
  },
  {
    id: 6,
    name: 'Design & Creative',
    talentCount: 7000,
  },
  {
    id: 7,
    name: 'Product Management',
    talentCount: 6000,
  },
  {
    id: 8,
    name: 'Growth & Strategy',
    talentCount: 8000,
  },
];

const Tooltip = ({ amount = 0 }) => {
  return (
    <div className={styles.Tooltip_wrapper}>
      <div className={styles.Tooltip_tip}>
        <div className="flex items-center space-x-2">
          <TalentTooltipIcon className="text-[#3ADB9A]" />
          <span className="text-[12px] font-[600]">
            {amount === 0 ? '0' : formatThousands(amount)}
          </span>
        </div>
      </div>
    </div>
  );
};

const ChartItem = ({
  height,
  amount,
  name,
  highestValue,
  activeBar,
  clickHandler,
}) => {
  const [barHeight, setBarHeight] = useState('0px');
  useEffect(() => {
    setTimeout(() => {
      setBarHeight(highestValue ? '182px' : `${height}px`);
    }, 500);
  }, []);
  return (
    <div
      role="button"
      className="flex h-[269px] min-w-[90px] max-w-[91px] flex-col justify-end"
      onClick={clickHandler}
    >
      {activeBar && <Tooltip amount={amount ?? 'Data not available'} />}
      {/* Amount Bar */}
      <div className="flex justify-center">
        {amount !== 0 && (
          <div
            className={clsx(
              'animate-bar',
              'rounded-[15px] transition-all delay-100 ease-in-out',
              { 'bg-violet-500 ': activeBar },
              { 'bg-violet-50': !activeBar }
            )}
            style={{
              height: barHeight,
              width: '40px',
              transition: 'height 0.5s',
            }}
          ></div>
        )}
      </div>
      {/* Name */}
      <div className="mt-[11px] h-[36px] text-center text-[12px] text-neutral-800 line-clamp-2">
        {name}
      </div>
    </div>
  );
};

const TalentDistributionChart = ({
  items = TEST_DATA,
  activeItem,
  setActiveItem,
}) => {
  const highestValue = findHighestValue(
    items.map((item) => (item.talentCount ? item.talentCount : 0))
  );

  useEffect(() => {
    if (items.length) {
      setActiveItem(
        items[
          highestValue
            ? // if some items has same value, it automatically set first item as active
              items.findIndex((item) => item.talentCount === highestValue)
            : 0
        ]?.id
      );
    }
  }, [highestValue, items, setActiveItem]);
  return (
    <section className="-mt-2 flex max-w-full flex-nowrap justify-between overflow-x-auto">
      {items
        .map((item) => {
          return {
            ...item,
            talentCount: item.talentCount ? item.talentCount : 0,
          };
        })
        .sort((a, b) => b.talentCount - a.talentCount)
        .map((item, idx) => {
          return (
            <ChartItem
              key={idx}
              name={item.name}
              height={findRelativeValue(item.talentCount, highestValue)}
              highestValue={item.talentCount === highestValue}
              activeBar={item.id === activeItem}
              clickHandler={() => setActiveItem(item.id)}
              amount={item.talentCount}
            />
          );
        })}
    </section>
  );
};

export default TalentDistributionChart;

/**
 * Find relative value
 * @param {number} value
 * @param {number} highestValue
 */
const findRelativeValue = (value, highestValue) => {
  return Math.floor((value / highestValue) * 180);
};

/**
 * Find highest value
 * @param {array} values
 * @returns {number}
 */
const findHighestValue = (values) => {
  return Math.max(...values);
};
